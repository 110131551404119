/* Generated by Glyphter (http://www.glyphter.com) on  Wed May 06 2020*/
@font-face {
  font-family: 'Property Nexus';
  src: url('../fonts/Property-Nexus.eot');
  src: url('../fonts/Property-Nexus.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Property-Nexus.woff') format('woff'),
       url('../fonts/Property-Nexus.ttf') format('truetype'),
       url('../fonts/Property-Nexus.svg#Property-Nexus') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-pn-']:before{
display: inline-block;
 font-family: 'Property Nexus';
 font-style: normal;
 font-weight: bold;
 line-height: inherit;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale
}
.icon-pn-Activity:before{content:'\0041';}
.icon-pn-Activity_circle:before{content:'\0042';}
.icon-pn-Company:before{content:'\0043';}
.icon-pn-Company_circle:before{content:'\0044';}
.icon-pn-Contact:before{content:'\0045';}
.icon-pn-Contact_circle:before{content:'\0046';}
.icon-pn-Dashboard:before{content:'\0047';}
.icon-pn-Dashboard_circle:before{content:'\0048';}
.icon-pn-Down:before{content:'\0049';}
.icon-pn-Edit:before{content:'\004a';}
.icon-pn-Internal-Broker:before{content:'\004b';}
.icon-pn-Internal-Broker_circle:before{content:'\004c';}
.icon-pn-Lead:before{content:'\004d';}
.icon-pn-Lead_circle:before{content:'\004e';}
.icon-pn-Listing:before{content:'\004f';}
.icon-pn-Listing_circle:before{content:'\0050';}
.icon-pn-Locked:before{content:'\0051';}
.icon-pn-Offer:before{content:'\0052';}
.icon-pn-Offer_circle:before{content:'\0053';}
.icon-pn-Payment:before{content:'\0054';}
.icon-pn-Payment_circle:before{content:'\0055';}
.icon-pn-Profile:before{content:'\0056';}
.icon-pn-Project:before{content:'\0057';}
.icon-pn-Project_circle:before{content:'\0058';}
.icon-pn-Property:before{content:'\0059';}
.icon-pn-Property_circle:before{content:'\005a';}
.icon-pn-Settings:before{content:'\0061';}
.icon-pn-Sign-In:before{content:'\0062';}
.icon-pn-Sign-Out:before{content:'\0063';}
.icon-pn-Teams:before{content:'\0064';}
.icon-pn-Transaction:before{content:'\0065';}
.icon-pn-Transaction_circle:before{content:'\0066';}
.icon-pn-Users:before{content:'\0067';}
.icon-pn-Snag:before{content:'\0068';}
.icon-pn-Snag_circle:before{content:'\0069';}
