@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.accordionjs {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;
  margin-bottom: 20px;

  .acc_section {
    border: 1px solid #eee;
    position: relative;
    margin-top: -1px;

    &:first-of-type {
      .acc_head {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
    }

    &:last-of-type {
      .acc_content {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    &.acc_active {
      .acc_head {
        background: #17b794;
        border-bottom: 1px solid #eee;
        color: #fff;
      }

      .acc_content {
        display: block;
      }
    }

    .acc_head {
      position: relative;
      background: #fff;
      padding: 10px;
      display: block;
      cursor: pointer;

      h3 {
        line-height: 1;
        margin: 5px;
        font-size: 16px;

        .fa {
          margin-right: map-get($spacers, 2);
        }
      }
    }

    .acc_content {
      padding: 10px 0 0;
    }
  }
}
