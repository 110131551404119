/*-- Horizonatl-menu Defalut --*/
.hor-skin1 .horizontal-main {
  /* border-bottom: 1px solid rgba(67, 87, 133, .2); */
}

.hor-skin1 .horizontalMenu>.horizontalMenu-list>li {

}
.hor-skin1 .horizontalMenu > .horizontalMenu-list > li:nth-child(1)::before,
.hor-skin1 .horizontalMenu > .horizontalMenu-list > li::after {
  width: 2px;
  background: #fff;
  content: " ";
  position: absolute;
  top: -3px;
  right: 0;
  height: 48px;
  z-index: 1000;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li  {
	margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.hor-skin1 .horizontalMenu > .horizontalMenu-list > li:nth-child(1)::before {
  left: 0;
}

.hor-skin1 .horizontalMenu > .horizontalMenu-list > li.active {
  background: #8DB8C9;
  border-top: 3px solid #182852;
}

.hor-skin1 .horizontalMenu > .horizontalMenu-list > li:hover {
  background: #8DB8C9;
  border-top: 3px solid #182852;
}
.hor-skin1 .horizontalMenu > .horizontalMenu-list > li:hover > a:hover {
  color: #182852
}

.horizontalMenu-list > .dropdown-item:hover {
  background: #8DB8C9;
}

.horizontalMenu-list > .horizontalMenu-list > li:hover .dropdown-item:hover {
  color: #182852
}

.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>a{
	color: #182852;
	border-left: none;
}


.hor-skin1 .hor-skin1 .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: #182852;
    text-decoration: none;
}
.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: #58595B;
    text-decoration: none;
}
.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a{
	color: #58595B;
}
.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: #182852;
    text-decoration: none;
}
.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>a:hover{
    color: #182852;
    text-decoration: none;
}
.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
	text-decoration: none;
	color:#182852;
}
.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover{
	text-decoration: none;
	color:#182852;
}
.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a:hover{
	text-decoration: none;
	color:#182852;
}

@media only screen and (max-width: 991px) {
  .hor-skin1 .horizontalMenu > .horizontalMenu-list > li:nth-child(1)::before,
  .hor-skin1 .horizontalMenu > .horizontalMenu-list > li::after {
    width: 0px;
  }
}

@media only screen and (max-width: 991px) {
	.hor-skin1 .horizontalMenu {
		width: 100%;
		background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li {
		border-bottom:1px solid rgba(67, 87, 133, .2);
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>a {
		color: #000;
		line-height: 25px;
		border-bottom: 1px solid;
		border-bottom-color: rgba(255, 255, 255, 0.13);
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
		border-top: solid 1px rgba(255, 255, 255, 0.13);
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>a>.fa {
		font-size: 16px;
		color: #000;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li:hover>a {
		background-color: rgba(0, 0, 0, 0.08);
		text-decoration: none;
	}
	/* ================== Mobile Slide Down Links CSS ================== */
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
		background-color: rgba(255,255,255,0.04);
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
		color: #58595B;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li span+a {
		padding-right: 30px;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a>.fa {
		display: none;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
		background-color: #e7e7e7;
		color: #6b6f80;
		text-decoration: underline;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu li:hover>a {
		background-color: #e7e7e7;
		color: #6b6f80;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
		color: #58595B !important;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
		background-color: #e7e7e7;
		color: #6b6f80;
		text-decoration: underline;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active {
		color: #6b6f80;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li:hover>a {
		color: #6b6f80;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
		color: #6b6f80;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
		background-color: #e7e7e7;
		color: #6b6f80;
		text-decoration: underline;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a.active {
		color: #6b6f80;
	}
	/* ================== Mobile Mega Menus CSS  ================== */
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu {
		color: #6b6f80;
		border-bottom: 1px solid rgba(255, 255, 255, 0.13);
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
		color: #6b6f80;
	}
	.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu>ul>li>a {
		background-color: #e7e7e7;
		color: #6b6f80;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu>ul>li>a:hover {
		background-color: #6b6f80;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu ul li.title {
		color: #6b6f80;
		background-color: transparent;
	}
	/* ================== Mobile Header CSS ================== */
	.hor-skin1 .horizontal-header {
		background-color: #eaecf0;
	}
	.hor-skin1 .callusbtn {
		color:#6b6f80;
	}
	.hor-skin1 .callusbtn:hover .fa {
		color:#6b6f80;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
		background: rgba(227, 212, 255,0.2);
		color:#182852;
		text-decoration: none;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu li:hover>a {
		background:#fff;
		color: #182852 !important;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li:hover>a {
		background:#fff;
		color:#182852 !important;
		text-decoration: none;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li:hover>a{
		color:#182852 ;
		text-decoration: none;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li>a.active>.fa{
		color: #182852 !important;
	}
	.hor-skin1 .horizontalMenu>.horizontalMenu-list>li:hover>a>.fa{
		color: #182852 !important;
	}

	/*End Media Query*/
}

/*-- Horizonatl-menu color --*/
.hor-skin2 .horizontal-main {
    background: #182852;
    border-bottom: 1px solid rgba(67, 87, 133, .2);
}
.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>a{
	color:#fff;
	border-left: 1px solid rgba(255, 255, 255, .2);
	margin:0;
}
.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: #182852;
    text-decoration: none;
	background:#071331;
}
.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a{
	color: #58595B;
}
.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: #fff;
    text-decoration: none;
}
.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>a:hover{
    color: #fff;
    text-decoration: none;
	background:#071331;
}
.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
	text-decoration: none;
	color:#182852;
}
.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover{
	text-decoration: none;
	color:#182852;
}
.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a:hover{
	text-decoration: none;
	color:#182852;
}

@media only screen and (max-width: 991px) {
	.hor-skin2 .horizontalMenu {
		width: 100%;
		background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li {
		border-bottom:1px solid rgba(67, 87, 133, .2);
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>a {
		color: #fff;
		line-height: 25px;
		border-bottom: 1px solid;
		border-bottom-color: rgba(255, 255, 255, 0.13);
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
		border-top: solid 1px rgba(255, 255, 255, 0.13);
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>a>.fa {
		font-size: 16px;
		color: #000;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li:hover>a {
		background-color: rgba(0, 0, 0, 0.08);
		text-decoration: none;
	}
	/* ================== Mobile Slide Down Links CSS ================== */
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
		background-color: rgba(255,255,255,0.04);
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
		color: #fff;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li span+a {
		padding-right: 30px;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a>.fa {
		display: none;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
		background-color: #e7e7e7;
		color: #6b6f80;
		text-decoration: underline;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu li:hover>a {
		background-color: #e7e7e7;
		color: #6b6f80;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
		color: #fff !important;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
		background-color: #e7e7e7;
		color: #6b6f80;
		text-decoration: underline;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active {
		color: #6b6f80;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li:hover>a {
		color: #6b6f80;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
		color: #6b6f80;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
		background-color: #e7e7e7;
		color: #6b6f80;
		text-decoration: underline;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a.active {
		color: #6b6f80;
	}
	/* ================== Mobile Mega Menus CSS  ================== */
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu {
		color: #6b6f80;
		border-bottom: 1px solid rgba(255, 255, 255, 0.13);
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
		color: #6b6f80;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu>ul>li>a {
		background-color: #e7e7e7;
		color: #6b6f80;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu>ul>li>a:hover {
		background-color: #6b6f80;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu ul li.title {
		color: #6b6f80;
		background-color: transparent;
	}
	/* ================== Mobile Header CSS ================== */
	.hor-skin2 .horizontal-header {
		background-color: #eaecf0;
	}
	.hor-skin2 .callusbtn {
		color:#6b6f80;
	}
	.hor-skin2 .callusbtn:hover .fa {
		color:#6b6f80;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
		background: rgba(227, 212, 255,0.2);
		color:#182852;
		text-decoration: none;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu li:hover>a {
		background:#fff;
		color: #182852 !important;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li:hover>a {
		background:#071331;
		color:#fff !important;
		text-decoration: none;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li:hover>a{
		color:#182852 ;
		text-decoration: none;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li>a.active>.fa{
		color: #182852 !important;
	}
	.hor-skin2 .horizontalMenu>.horizontalMenu-list>li:hover>a>.fa{
		color: #182852 !important;
	}
	.hor-skin2 .active .horizontalMenu>.horizontalMenu-list{
		background:#182852;
	}
	/*End Media Query*/
}

/*-- Horizonatl-menu Dark --*/
.hor-skin3 .horizontal-main {
    background: #071331;
    border-bottom: 1px solid rgba(67, 87, 133, .2);
}
.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>a{
	color:#fff;
	border-left: 1px solid rgba(255, 255, 255, .2);
	margin:0;
}
.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: #182852;
    text-decoration: none;
	background:#182852;
}
.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a{
	color: #58595B;
}
.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>a.active {
    color: #fff;
    text-decoration: none;
}
.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>a:hover{
    color: #fff;
    text-decoration: none;
	background:#4174f5;
}
.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
	text-decoration: none;
	color:#182852;
}
.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover{
	text-decoration: none;
	color:#182852;
}
.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a:hover{
	text-decoration: none;
	color:#182852;
}
@media only screen and (max-width: 991px) {
	.hor-skin3 .horizontalMenu {
		width: 100%;
		background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li {
		border-bottom:1px solid rgba(67, 87, 133, .2);
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>a {
		color: #fff;
		line-height: 25px;
		border-bottom: 1px solid;
		border-bottom-color: rgba(255, 255, 255, 0.13);
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
		border-top: solid 1px rgba(255, 255, 255, 0.13);
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>a>.fa {
		font-size: 16px;
		color: #000;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li:hover>a {
		background-color: rgba(0, 0, 0, 0.08);
		text-decoration: none;
	}
	/* ================== Mobile Slide Down Links CSS ================== */
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
		background-color: rgba(255,255,255,0.04);
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
		color: #fff;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li span+a {
		padding-right: 30px;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a>.fa {
		display: none;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
		background-color: #e7e7e7;
		color: #6b6f80;
		text-decoration: underline;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu li:hover>a {
		background-color: #e7e7e7;
		color: #6b6f80;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
		color: #fff !important;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
		background-color: #e7e7e7;
		color: #6b6f80;
		text-decoration: underline;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active {
		color: #6b6f80;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li:hover>a {
		color: #6b6f80;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
		color: #6b6f80;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
		background-color: #e7e7e7;
		color: #6b6f80;
		text-decoration: underline;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a.active {
		color: #6b6f80;
	}
	/* ================== Mobile Mega Menus CSS  ================== */
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu {
		color: #6b6f80;
		border-bottom: 1px solid rgba(255, 255, 255, 0.13);
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
		color: #6b6f80;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu>ul>li>a {
		background-color: #e7e7e7;
		color: #6b6f80;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu>ul>li>a:hover {
		background-color: #6b6f80;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu ul li.title {
		color: #6b6f80;
		background-color: transparent;
	}
	/* ================== Mobile Header CSS ================== */
	.hor-skin3 .horizontal-header {
		background-color: #eaecf0;
	}
	.hor-skin3 .callusbtn {
		color:#6b6f80;
	}
	.hor-skin3 .callusbtn:hover .fa {
		color:#6b6f80;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
		background: rgba(227, 212, 255,0.2);
		color:#182852;
		text-decoration: none;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu li:hover>a {
		background:#fff;
		color: #182852 !important;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li:hover>a {
		background:#4174f5;
		color:#fff !important;
		text-decoration: none;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li:hover>a{
		color:#182852 ;
		text-decoration: none;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li>a.active>.fa{
		color: #182852 !important;
	}
	.hor-skin3 .horizontalMenu>.horizontalMenu-list>li:hover>a>.fa{
		color: #182852 !important;
	}
	.hor-skin3 .active .horizontalMenu>.horizontalMenu-list{
		background:#071331;
	}
	/*End Media Query*/
}

/*-- Horizonatl-menu Style1 --*/
@media (min-width: 992px) {
	.menu1 .header.top-header{
		border-bottom: 0;
	}
	.menu1 .horizontal-main{
		border-bottom: 0;
	}
}
.menu1 .horizontalMenu>.horizontalMenu-list>li>a{
	border: 1px solid rgba(67, 87, 133, .2);
	margin:3px;
	border-radius:3px;
}
.menu1 .hor-menu .horizontalMenu>.horizontalMenu-list>li>a {
	padding: 0.6rem 1.5rem 0.6rem;
}
@media (max-width: 992px) {
	.menu1 .horizontalMenu>.horizontalMenu-list>li{
		border-bottom:0
	}
}
.menu1 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu{
	top:45px;
}
.menu1 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu{
	top:45px;
}
/* Combination side bar */
.menu1 .app.sidebar-mini .comb-sidebar .side-menu__item{
	padding: 8px 19px 8px 34px;
}
.menu1 .app.sidebar-mini .app-header.comb-header{
	border-bottom:0;
}
@media (min-width: 992px){
	.menu1 .app.sidebar-mini .horizontalMenu>.horizontalMenu-list>li:first-child>a{
		margin-left: 5px;
		border-left: 1px solid rgba(67, 87, 133, .2);
	}
	.menu1 .app.sidebar-mini.sidenav-toggled .horizontalMenu>.horizontalMenu-list>li:first-child>a{
		margin-left:0;
	}
	.menu1 .app-sidebar.comb-sidebar{
		border: 1px solid rgba(67, 87, 133, .2);
		border-radius:3px;
		margin-top: 4.8rem;
		margin-left: 5px;
		width: 225px;
	}
}
.menu1 .app.sidebar-mini .app-header{
	border-bottom:1px solid rgba(67, 87, 133, .2);
}

/*-- Horizonatl-menu Style2 --*/
.menu2 .header.top-header{
	border-bottom: 1px solid rgba(67, 87, 133, .2);
}
.menu2 .horizontal-main{
	border-bottom: 1px solid rgba(67, 87, 133, .2);
}
.menu2 .horizontalMenu>.horizontalMenu-list>li>a{
	border-left: 1px solid rgba(67, 87, 133, .2);
}
.menu2 .hor-menu .horizontalMenu>.horizontalMenu-list>li>a {
    padding: 0.5rem 2.5rem 0.5rem;
}
.menu2 .horizontalMenu>.horizontalMenu-list>li:last-child>a {
    border-right: 0px;
}
.menu2 .horizontalMenu>.horizontalMenu-list>li>a i{
	display: -webkit-box;
    text-align: -webkit-center;
	margin:0 auto;
}
.menu2 .horizontalMenu>.horizontalMenu-list>li>a .horizontal-icon{
	display:contents;
}
.menu2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu{
	top:58px;
}
.menu2 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu{
	top:58px;
}
@media only screen and (max-width: 991px){
	.menu2 .horizontalMenu>.horizontalMenu-list>li>a{
		text-align:center;
	}
	.menu2 .horizontalMenu>.horizontalMenu-list>li>a .horizontal-icon{
		display:none;
	}
	.menu2 .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu{
		top:0;
		border-top:1px solid rgba(67, 87, 133, .2) !important;
	}
	.menu2 .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu{
		top:0;
		border-top:1px solid rgba(67, 87, 133, .2) !important;
	}
}
/*-- Left-menu Defalut --*/
@media (max-width: 480px) {}
@media print {
	.side-skin1 .app-content {
		margin: 0;
		padding: 0;
		background-color: #fff;
	}
}
.side-skin1  .app-header {
	border-bottom: 1px solid rgba(67, 87, 133, .2);
}

.side-skin1  .app-header__logo {
	color: #fff;
}
.side-skin1  .app-sidebar__toggle {
	color: #58595B;
}
.side-skin1  .app-sidebar__toggle:hover {
	color: #58595B;
}
/*@media (max-width: 360px) {
	.app-sidebar__toggle {
		line-height: 2;
	}
}*/
.side-skin1  .app-nav__item {
	color: #a8a8a8;
}
.side-skin1  .app-nav__item:hover, .app-nav__item:focus {
	background: rgba(0, 0, 0, 0.1);
	color: #f6f6f6;
}
.side-skin1  .app-search__input {
	background-color: rgba(255, 255, 255, 0.8);
}
.side-skin1  .app-search__button {
	color: rgba(0, 0, 0, 0.8);
}
.side-skin1  .app-notification__title {
	background-color: rgba(0, 150, 136, 0.4);
	color: #333;
}
.side-skin1  .app-notification__footer {
	background-color: #eee;
}
.side-skin1  .app-notification__content::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
}
.side-skin1  .app-notification__item {
	border-bottom: 1px solid #ddd;
}
.side-skin1  .app-notification__item:focus, .app-notification__item:hover {
	background-color: #e0e0e0;
}
.side-skin1  .app-sidebar {
	background: #fff !important;
	border-right:1px solid rgba(67, 87, 133, .2)
}
.side-skin1  .app-sidebar::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
}
.side-skin1  .app-sidebar__user {
	color: #8e9cad;
	border-bottom: 1px solid rgba(67, 87, 133, .2);
}
.side-skin1  .app-sidebar__user-name, .app-sidebar__user-designation {
	color: #e5e9ec;
}
.side-skin1  .side-menu__item {
	color:#58595B;
	border-bottom: 1px solid rgba(67, 87, 133, .2)
}
.side-skin1  .side-menu__item.active, .side-menu__item:hover, .side-menu__item:focus {
	color: #182852;
    background: #fff;
}
@media (min-width: 768px){
	.side-skin1  .app.sidebar-mini.sidenav-toggled .side-menu__label {
		color: rgba(255, 255, 255, 0.8);
	}
}
.side-skin1  .side-menu__item.active .side-menu__icon, .side-menu__item:hover .side-menu__icon, .side-menu__item:focus .side-menu__icon{
	color:#182852;
}
.side-skin1  .slide-item.active, .slide-item:hover, .slide-item:focus {
	color: #b48ef4;
}
.side-skin1  .slide-menu a.active{
	color:#182852;
    background: #fff;
}
.side-skin1  .slide-item.active, .slide-item:hover, .slide-item:focus {
	color: #182852;
    background:#fff;
}
.side-skin1  .side-menu .side-menu__icon {
	color: #58595B;
}
.side-skin1  .slide-item {
	color: #58595B;
	border-bottom: 1px solid rgba(67, 87, 133, .06);
}
.side-skin1  .slide-menu li:last-child .slide-item {
	border-bottom: 1px solid rgba(67, 87, 133, .2);
}
.side-skin1  .slide-item .icon {
	margin-right: 5px;
}
@media (min-width: 768px) {
	.side-skin1  .sidebar-mini.sidenav-toggled .side-menu__label {
		background: #ffffff;
		color: #a8a8a8;
	}
	.side-skin1  .app.sidebar-mini.sidenav-toggled .side-menu__label{
		color: #5c6287;
	}
}
.side-skin1  .app-title {
	background-color: #FFF;
}

/*-- Left-menu Color --*/
@media (max-width: 480px) {}
@media print {
	.side-skin2 .app-content {
		margin: 0;
		padding: 0;
		background-color: #fff;
	}
}
.side-skin2 .app-header {
	border-bottom: 1px solid rgba(67, 87, 133, .2);
}

.side-skin2 .app-header__logo {
	color: #fff;
}
.side-skin2 .app-sidebar__toggle {
	color: #58595B;
}
.side-skin2 .app-sidebar__toggle:hover {
	color: #58595B;
}
/*@media (max-width: 360px) {
	.app-sidebar__toggle {
		line-height: 2;
	}
}*/
.side-skin2 .app-nav__item {
	color: #a8a8a8;
}
.side-skin2 .app-nav__item:hover, .app-nav__item:focus {
	background: rgba(0, 0, 0, 0.1);
	color: #f6f6f6;
}
.side-skin2 .app-search__input {
	background-color: rgba(255, 255, 255, 0.8);
}
.side-skin2 .app-search__button {
	color: rgba(0, 0, 0, 0.8);
}
.side-skin2 .app-notification__title {
	background-color: rgba(0, 150, 136, 0.4);
	color: #333;
}
.side-skin2 .app-notification__footer {
	background-color: #eee;
}
.side-skin2 .app-notification__content::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
}
.side-skin2 .app-notification__item {
	border-bottom: 1px solid #ddd;
}
.side-skin2 .app-notification__item:focus, .app-notification__item:hover {
	background-color: #e0e0e0;
}
.side-skin2 .app-sidebar {
	background:#162e6b !important;
	border-right:1px solid rgba(255, 255, 255, .06)
}
.side-skin2 .app-sidebar::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
}
.side-skin2 .app-sidebar__user {
	color: #8e9cad;
	border-bottom: 1px solid rgba(255, 255, 255, .06);
}
.side-skin2 .app-sidebar__user-name, .app-sidebar__user-designation {
	color: #e5e9ec;
}
.side-skin2 .side-menu__item {
	color:#fff;
	border-bottom: 1px solid rgba(255, 255, 255, .06)
}
.side-skin2 .side-menu__item.active, .side-menu__item:hover, .side-menu__item:focus {
	color: #fff;
    background: rgba(255,255,255,0.08);
}
@media (min-width: 768px){
	.side-skin2 .app.sidebar-mini.sidenav-toggled .side-menu__label {
		color: rgba(255, 255, 255, 0.8);
	}
}
.side-skin2 .side-menu__item.active .side-menu__icon, .side-menu__item:hover .side-menu__icon, .side-menu__item:focus .side-menu__icon{
	color:#fff;
}
.side-skin2 .slide-item.active, .slide-item:hover, .slide-item:focus {
	color: #b48ef4;
}
.side-skin2 .slide-menu a.active{
	color:#fff;
    background: rgba(255,255,255,0.04);
}
.side-skin2 .slide-item.active, .slide-item:hover, .slide-item:focus {
	color: #fff;
    background:rgba(255,255,255,0.04);
}
.side-skin2 .side-menu .side-menu__icon {
	color: #fff;
}
.side-skin2 .slide-item {
	color: #fff;
	border-bottom: 1px solid rgba(67, 87, 133, .06);
}
.side-skin2 .slide-menu li:last-child .slide-item {
	border-bottom: 1px solid rgba(255, 255, 255, .06);
}
.side-skin2 .slide-item .icon {
	margin-right: 5px;
}
@media (min-width: 768px) {
	.side-skin2 .sidebar-mini.sidenav-toggled .side-menu__label {
		background: #ffffff;
		color: #a8a8a8;
	}
	.side-skin2 .app.sidebar-mini.sidenav-toggled .side-menu__label{
		color: #5c6287;
	}
}
.side-skin2 .app-title {
	background-color: #FFF;
}
.side-skin2 .user-info .text-dark{
	color: #fff !important;
}
.side-skin2 .user-info .text-muted{
	color:#c2cbe2 !important
}
.side-skin2 .sidebar-navs .nav-link.border{
	border:1px solid rgba(255,255,255,0.2) !important
}
.side-skin2 .sidebar-navs a{
	color:#fff !important;
}

/*-- Left-menu Dark --*/
@media (max-width: 480px) {}
@media print {
	.side-skin3 .app-content {
		margin: 0;
		padding: 0;
		background-color: #fff;
	}
}
.side-skin3 .app-header {
	border-bottom: 1px solid rgba(67, 87, 133, .2);
}

.side-skin3 .app-header__logo {
	color: #fff;
}
.side-skin3 .app-sidebar__toggle {
	color: #58595B;
}
.side-skin3 .app-sidebar__toggle:hover {
	color: #58595B;
}
.side-skin3 .app-nav__item {
	color: #a8a8a8;
}
.side-skin3 .app-nav__item:hover, .app-nav__item:focus {
	background: rgba(0, 0, 0, 0.1);
	color: #f6f6f6;
}
.side-skin3 .app-search__input {
	background-color: rgba(255, 255, 255, 0.8);
}
.side-skin3 .app-search__button {
	color: rgba(0, 0, 0, 0.8);
}
.side-skin3 .app-notification__title {
	background-color: rgba(0, 150, 136, 0.4);
	color: #333;
}
.side-skin3  .app-notification__footer {
	background-color: #eee;
}
.side-skin3 .app-notification__content::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
}
.side-skin3 .app-notification__item {
	border-bottom: 1px solid #ddd;
}
.side-skin3 .app-notification__item:focus, .app-notification__item:hover {
	background-color: #e0e0e0;
}
.side-skin3 .app-sidebar {
	background: #071331 !important;
	border-right:1px solid rgba(255, 255, 255, .05)
}
.side-skin3 .app-sidebar::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
}
.side-skin3 .app-sidebar__user {
	color: #8e9cad;
	border-bottom: 1px solid rgba(255, 255, 255, .05);
}
.side-skin3 .app-sidebar__user-name, .app-sidebar__user-designation {
	color: #e5e9ec;
}
.side-skin3 .side-menu__item {
	color:#fff;
	border-bottom: 1px solid rgba(255, 255, 255, .05)
}
.side-skin3 .side-menu__item.active, .side-menu__item:hover, .side-menu__item:focus {
	color: #fff;
    background: #182852;
}
@media (min-width: 768px){
	.side-skin3 .app.sidebar-mini.sidenav-toggled .side-menu__label {
		color: rgba(255, 255, 255, 0.8);
	}
}
.side-skin3 .side-menu__item.active .side-menu__icon, .side-menu__item:hover .side-menu__icon, .side-menu__item:focus .side-menu__icon{
	color:#fff;
}
.side-skin3 .slide-item.active, .slide-item:hover, .slide-item:focus {
	color: #b48ef4;
}
.side-skin3 .slide-menu a.active{
	color:#fff;
    background: rgba(255,255,255,0.07);
}
.side-skin3 .slide-item.active, .slide-item:hover, .slide-item:focus {
	color: #fff;
    background:rgba(255,255,255,0.07);
}
.side-skin3 .side-menu .side-menu__icon {
	color: #fff;
}
.side-skin3 .slide-item {
	color: #fff;
	border-bottom: 1px solid rgba(67, 87, 133, .06);
}
.side-skin3 .slide-menu li:last-child .slide-item {
	border-bottom: 1px solid rgba(255, 255, 255, .05);
}
.side-skin3 .slide-item .icon {
	margin-right: 5px;
}
@media (min-width: 768px) {
	.side-skin3 .sidebar-mini.sidenav-toggled .side-menu__label {
		background: #ffffff;
		color: #a8a8a8;
	}
	.side-skin3 .app.sidebar-mini.sidenav-toggled .side-menu__label{
		color: #5c6287;
	}
}
.side-skin3 .app-title {
	background-color: #FFF;
}
.side-skin3 .user-info .text-dark{
	color: #fff !important;
}
.side-skin3 .user-info .text-muted{
	color:#c2cbe2 !important
}
.side-skin3 .sidebar-navs .nav-link.border{
	border:1px solid rgba(255,255,255,0.07) !important
}
.side-skin3 .sidebar-navs a{
	color:#fff !important;
}

/*--Body Skin Defalut --*/
.main1 body{
	background: #fff;
}
.main1 .card{
	border: 1px solid rgba(67, 87, 133, .2);
}

/*--Body Skin Style1 --*/
.main2 body{
	background: #eef1f9;
}

/*--Body Skin Style2 --*/
.main3 body{
	background:#eef1f9;
}
.main3 .card{
	box-shadow:0 0 0 0px rgb(216, 219, 228), 0 8px 16px 0 rgb(216, 219, 228);

}

/*--Font Style1 --*/
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

.font1 body{
	font-family: 'Roboto', sans-serif !important;
}
.font1 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Roboto', sans-serif ;
}

/*--Font Style2 --*/
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700');

.font2 body{
	font-family:  'Nunito', sans-serif !important;
}
.font2 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family:  'Nunito', sans-serif ;
}
.font2 h1, h2, h3{
	font-size: 1.65rem;
}

/*--Font Style3 --*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

.font3 body{
	font-family: 'Open Sans', sans-serif !important;
}
.font3 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Open Sans', sans-serif ;
}
.font3 h1, h2, h3{
	font-size: 1.65rem;
}

/*--Font Style4 --*/
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

.font4 body{
	font-family: 'Lato', sans-serif !important;
}
.font4 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Lato', sans-serif ;
}
