@media only screen and (min-width: 992px) {
  .horizontal-mainwrapper {
    max-width: 960px;

    .horizontalMenu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .horizontalMenu-list {
        width: auto;
        margin: 0;
        display: flex;
        flex-direction: row;

        > .dropdown {
          display: flex;
          flex-direction: row;
          align-items: center;

          a {
            margin: 0;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .horizontal-mainwrapper {
    max-width: 1200px;
  }
}
