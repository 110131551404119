@import "./theme/style";
@import "./theme/fade-up";
@import "./theme/horizontal";
@import "./theme/p-scrollbar";
@import "./theme/icons";
@import "./theme/plugin";
@import "./theme/skins";
@import "./Property-Nexus";
@import "bootstrap";

$color-invalid: #dc0441;
$color-valid: #2dce89;
$color-danger: #dc3545;
$color-link-block: #45aaf2;
$color-disabled: #aaaaaa;
$color-white: #ffffff;

$color-text: #58595b;
$color-text-a: #a7a9ac;
$color-text-b: #d1d3d4;
$color-text-c: #e6e7e8;
$color-light-blue: #8db8c9;
$color-blue: #4297cb;
$color-dark-blue: #182852;
$color-not-valid: #dc3545;

.card-calendar {
  .card-header-calendar {
    background: $color-text-a;
    border-bottom: 1px solid $color-dark-blue;
    color: $color-dark-blue;
    padding: 1.12rem 0.75rem !important;

    .card-title {
      font-size: 1.2rem;
    }
  }
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: $color-dark-blue;
  background-color: $color-text-c;
  border-color: $color-dark-blue;
}

.checkbox-bottom {
  margin-bottom: 1.37rem !important;
}

.break-line-before {
  margin-left: 1.2rem !important;
}

.button-clear {
  position: relative;
  &::after {
    clear: both;
  }
}
.button-clear::after {
  clear: both;
}

.show {
  .btn-secondary.dropdown-toggle {
    background-color: $color-dark-blue !important;
    border-color: $color-dark-blue !important;
  }
}

.fc-unthemed {
  .fc-header-toolbar {
    display: flex;
    flex-wrap: nowrap !important;
    background: $color-text-a;
    padding: 0.8rem 0.5rem !important;
    color: $color-dark-blue;
    margin-bottom: 0.8rem !important;

    .fc-center {
      h2 {
        white-space: nowrap;
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
      }
    }

    .fc-right,
    .fc-left {
      display: flex;
      flex-wrap: nowrap !important;
    }

    &::before {
      content: none;
    }

    .fc-button {
      letter-spacing: 0.03em;
      font-size: 0.8125rem;
      min-width: 2.375rem;
      color: $color-dark-blue;
      background-color: $color-text-c;
      border-color: $color-dark-blue;
      margin: 0.25rem !important;
      white-space: nowrap;
    }

    .fc-button:hover,
    .fc-button-primary.fc-button-active {
      color: $color-dark-blue;
      background-color: $color-light-blue;
      border-color: $color-dark-blue;
    }
  }

  .fc-view-container {
    .fc-dayGridMonth-view {
      background: $color-text-b !important;
      .fc-more-popover {
        .fc-event {
          border: none !important;
          margin-bottom: 3px;
        }
        .fc-content {
          border: none !important;
        }
      }
      .fc-widget-header {
        color: $color-dark-blue;
        background: $color-text-b !important;
        border-color: $color-text-a;
        th {
          padding: 0.5rem;
          border-color: $color-text-a;
        }
      }
      .fc-day-grid-container {
        .fc-row {
          background: $color-white !important;
        }
        tbody,
        thead,
        .fc-content {
          border: none;
        }
        td {
          border-color: $color-text-a;
        }
        .activity-open.fc-event {
          background: $color-blue;
          border: none;
        }
        .activity-completed.fc-event {
          background: $color-text-a;
          border: none;
        }
        .activity-cancelled.fc-event {
          background: $color-dark-blue;
          border: none;
        }
        .fc-day-grid-event {
          margin: 1px 2px 2px;
        }
      }
    }
    .fc-listWeek-view,
    .fc-listDay-view {
      border: none;
      .fc-list-table {
        border-collapse: collapse;
        .fc-list-heading {
          background: $color-text-b !important;
          color: $color-dark-blue;
          border-bottom: 1px solid $color-text;
          td {
            border-top: 1px solid $color-dark-blue;
          }
          &:first-child td {
            border: none;
          }
        }
        .fc-list-item {
          td {
            background: $color-white;
          }
        }
        .activity-open {
          .fc-event-dot {
            background: $color-blue;
          }
        }
        .activity-completed {
          .fc-event-dot {
            background: $color-text-a;
          }
        }
        .activity-cancelled {
          .fc-event-dot {
            background: $color-dark-blue;
          }
        }
      }
    }
  }
}

.activtity-plaintext-label {
  min-height: 36px;
  padding: 0.375rem 0rem;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 0;
}

.grid-divider {
  // overflow-x: hidden;
  position: relative;
}

.grid-divider > [class*="col-"]:nth-child(n + 2):after {
  content: "";
  background-color: $color-dark-blue;
  position: absolute;
  top: 0;
  bottom: 0;

  @media (max-width: 767px) {
    width: 100%;
    height: 1px;
    left: 0;
    top: -4px;
  }
  @media (min-width: 768px) {
    width: 1px;
    height: auto;
    left: -1px;
  }
}

.list-group-item {
  background-color: $color-text-c;
  color: $color-text;
  .link {
    cursor: pointer;
  }
  .open {
    color: $color-blue;
  }
  .completed {
    color: $color-text-a;
  }
  .cancelled {
    color: $color-dark-blue;
  }
}

.custom-bg-datetime-container {
  position: inherit;
  z-index: 10000;
  right: 0;
  border: 1px solid $color-dark-blue;
  margin: 4px 0 4px;
  background: $color-white;
}

.custom-bg-datetime {
  width: 100% !important;
  position: absolute;
}

.time-input-block {
  border-bottom: 1px solid $color-dark-blue;
  padding: 1em;
}

.card {
  margin-bottom: 0;
}

.icon-collapse {
  position: absolute;
  left: 15px;
  top: 15px;
}

.table-header-custom,
.card-header {
  background: $color-text-b;
  border-bottom: 1px solid $color-dark-blue;
}

.image-media-block {
  background: $color-text-b;
}

.tabs-menu-activity {
  ul {
    li {
      .active {
        border-bottom: 3px solid #182852;
      }
      a {
        padding: 10px 5px 11px 5px;
      }
    }
  }
}

.card-header {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  &:first-child {
    border-bottom: 1px solid $color-dark-blue;
  }
  .card-title {
    font-size: 1.2rem;
    color: $color-dark-blue;
  }
}

.no-gutter-last-child {
  button {
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.tab-content {
  .tab-pane {
    .row {
    }
  }
}

.btn-user {
  background-color: $color-dark-blue;
  border-color: $color-dark-blue;
  i {
    color: $color-white;
    font-size: 1.5rem;
  }
}

.text-dark-blue {
  color: $color-dark-blue;
}

.min-width-100 {
  min-width: 100px;
}

.tab_wrapper {
  position: relative;
  & > ul {
    clear: both;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    // border-top: 1px solid $color-text;
    border-bottom: 1px solid $color-text;
    // background: $color-text-c !important;
  }
}

.tab_wrapper * {
  box-sizing: border-box;
}

.tab_wrapper > ul li.active {
  background: $color-light-blue !important;
  color: $color-dark-blue;
  border-right: 2px solid $color-white !important;
  font-weight: 600;
}

.tab_wrapper > ul li {
  float: left;
  cursor: pointer;
  padding: 8px 14px;
  background: $color-text-c !important;
  font-size: 1.05em;
  border: none;
  border-right: 2px solid $color-white;
  position: relative;
  color: $color-dark-blue;
}

.tabs-menu-body {
  padding: 15px 2px;
  border: none;
}

.card,
.card-header,
.tab-menu-heading {
  border: none;
}

.card-header,
.card-body {
  padding: 0;
}

.table-icon-style {
  font-size: 1.9rem;
  font-weight: normal;
  color: $color-dark-blue;
}

.react-bootstrap-table {
  table {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    thead {
      th {
        border: 1px solid $color-text;
        background-color: $color-text-b;
      }
      .sorted {
        background-color: $color-text-a;
      }
    }
    tbody {
      td {
        position: relative;
        top: 2px;
        background-color: $color-text-c;
        border-bottom: 2px solid $color-white;
      }
    }
  }
}

.table-responsive {
  overflow-y: hidden;
}

.card-table {
  thead {
    tr:first-child {
      th {
        border-top: 1px solid $color-text;
      }
    }
    th {
      border-bottom: 1px solid $color-text;
      border-right: 1px solid $color-text;
      background-color: $color-text-b;
      &:last-child {
        border-right: none;
      }
    }
  }
  tbody {
    td {
      position: relative;
      top: 2px;
      background-color: $color-text-c;
      border-bottom: 2px solid $color-white;
    }
  }
}

.form-control-sm-custom {
  padding: 0.45rem 0.5rem;
}

.btn-header {
  color: $color-dark-blue;
  background-color: $color-text-c;
  border-color: $color-dark-blue;
}

.btn-header:hover {
  color: $color-white;
  background-color: $color-dark-blue;
  border-color: $color-dark-blue;
}

.react-bs-table-sizePerPage-dropdown.show,
.react-bs-table-sizePerPage-dropdown {
  .btn-secondary.dropdown-toggle,
  button {
    color: $color-white;
    background-color: $color-dark-blue;
    border-color: $color-dark-blue;
    &:hover {
      color: $color-white;
      background-color: $color-dark-blue;
      border-color: $color-dark-blue;
    }
  }
}

.acc_head {
  background-color: $color-text-c !important;
}

.text-blue {
  color: $color-dark-blue !important;
}

.accordionjs {
  .acc_section {
    &:last-of-type {
      border: none;
    }
  }
}

.form-group {
  margin-bottom: 0.5rem;
}

*:focus {
  outline: 0 !important;
}

.fade-out {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fade-in {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.table-link {
  color: $color-dark-blue !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.form-control-plaintext a:not(:first-child) {
  &::before {
    content: "\A";
    white-space: pre;
  }
}

.custom-control-label.before-padding-custom::before,
.custom-control-label.before-padding-custom::after {
  top: 0.6rem;
}

span.checked ~ .custom-control-label::before {
  color: $color-white;
  background-color: $color-dark-blue;
}

span.checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox-text {
  min-height: 2.25rem;
  border-bottom: 1px solid $color-text;
  .fe {
    margin-top: 11px;
  }
}

// sass-lint:disable no-important
input {
  &::placeholder {
    color: $color-text-a !important;
    opacity: 0.5;
  }
}

.form-group {
  .select-search-box {
    margin-bottom: 0px;
  }
}

.was-validated {
  .form-control:valid ~ div.select-search-box,
  .valid-element {
    border: 1px solid $color-valid;
  }
  .form-control:invalid ~ div.select-search-box,
  .invalid-element {
    border: 1px solid $color-invalid;
  }
}

.plaintext-label {
  min-height: 36px;
  padding: 0.375rem 0rem;
  font-size: 0.9rem;
  font-weight: 400;
  border-bottom: 1px solid $color-text;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: pre-wrap;
  position: relative;
  padding-right: 15px;
  .fe-edit-2 {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
}

.datatable-header-class {
  th {
    position: relative;
    width: 100%;

    .datatable-header-text {
      white-space: nowrap;
      width: calc(100vh - 20px);
      overflow-x: hidden;
    }
  }
}

.uploading-list-title {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.5rem;
}
.uploading-list .uploading-list-item {
  zoom: 1;
  padding: 0.5rem 0;
  border-top: solid 1px $color-text;
}
.uploading-list .uploading-list-item:before,
.uploading-list .uploading-list-item:after {
  content: " ";
  display: table;
}
.uploading-list .uploading-list-item:after {
  clear: both;
}
.uploading-list .uploading-list-item:first-child {
  border-top: none;
}
.uploading-list .uploading-list-item-wrapper {
  position: relative;
  padding: 0 20px 0 0;
  margin: 6px 0;
}
.uploading-list .uploading-list-item-name,
.uploading-list .uploading-list-item-size,
.uploading-list .uploading-list-item-uploaded-at {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  line-height: 1rem;
}
.uploading-list .uploading-list-item-name {
  margin-right: 15px;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.uploading-list .uploading-list-item-name .fe {
  color: #adb7be;
  margin: 0 5px 0 0;
  vertical-align: middle;
  font-size: 1rem;
}
.uploading-list .uploading-list-item-size,
.uploading-list .uploading-list-item-uploaded-at {
  color: #919fa9;
}
.uploading-list .uploading-list-item-close {
  border: none;
  background: none;
  color: #dbe4ea;
  font-size: 1.2rem;
  position: absolute;
  right: 0;
  top: -2px;
  cursor: pointer;
}
.uploading-list .uploading-list-item-close:hover {
  color: $color-danger;
}
.uploading-list .uploading-list-item-edit {
  border: none;
  background: none;
  color: #dbe4ea;
  font-size: 1.2rem;
  position: absolute;
  right: 25px;
  top: -2px;
  cursor: pointer;
}
.uploading-list .uploading-list-item-edit:hover {
  color: $color-dark-blue;
}
.uploading-list .uploading-list-item-progress,
.uploading-list .uploading-list-item-speed {
  font-size: 0.875rem;
}
.uploading-list .uploading-list-item-progress {
  float: left;
}
.uploading-list .uploading-list-item-speed {
  float: right;
}
.uploading-list .progress {
  margin-bottom: 0.4rem;
}
.uploading-list .progress {
  background-color: #f6f8fa;
  height: 5px;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #00a8ff;
}
.uploading-list .progress[value] {
  color: #00a8ff;
}
.uploading-list .progress[value]::-webkit-progress-value {
  background-color: #00a8ff;
}
.uploading-list .progress-bar {
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: #00a8ff;
}
.drop-zone {
  width: 100%;
  height: 205px;
  border: dashed 1px $color-text;
  text-align: center;
  padding: 25px 0 0;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  .fe {
    line-height: 60px;
    color: $color-text;
    font-size: 2.75rem;
  }
  .drop-zone-caption {
    font-size: 1rem;
    font-weight: 600;
    color: $color-text;
    margin: 0 0 1rem;
  }
}
.drop-zone.dragover {
  border-color: #00a8ff;
}

.btn-file {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.btn-file input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  height: 200px;
  width: 1000px;
  opacity: 0;
  cursor: pointer;
}
.uploading-container {
  zoom: 1;
  padding: 15px 25px 15px 15px;
}
.uploading-container:before,
.uploading-container:after {
  content: " ";
  display: table;
}
.uploading-container:after {
  clear: both;
}
.uploading-container .uploading-container-left {
  float: left;
  position: relative;
  z-index: 5;
  width: 220px;
}
.uploading-container .uploading-container-right {
  float: right;
  width: 100%;
  margin-left: -220px;
}
.uploading-container .uploading-container-right-in {
  margin-left: 220px;
}
@media (max-width: 544px) {
  .uploading-container .uploading-container-left,
  .uploading-container .uploading-container-right {
    float: none;
    width: auto;
    margin: 0;
  }
  .uploading-container .uploading-container-right-in {
    margin: 0;
  }
  .uploading-container .drop-zone {
    width: auto;
    margin: 0 0 1rem;
  }
}

.link-block {
  a {
    color: $color-dark-blue;
    border-bottom: 1px solid $color-dark-blue;
    &:hover {
      color: $color-dark-blue;
    }
  }
}

.badge-info {
  color: $color-white;
  background-color: $color-dark-blue;
}

.sub-menu {
  li {
    .new-record:before {
      content: "\e9b0";
      position: relative;
      font-family: "feather" !important;
      opacity: 0.6;
    }
  }
}

.icon-not-allowed {
  color: $color-disabled;
  background: linear-gradient(45deg, transparent calc(50% - 1px), $color-invalid calc(50% - 1px), $color-invalid calc(50% + 1px), transparent calc(50% + 1px));
}

.react-bootstrap-table-search {
  border: 1px solid #dee2e6;
  border-bottom: none;
  padding: 10px;
  color: #384b6e;
}

.app-content.page-body {
  background: url("../constants/images/background.jpg");
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #app-root,
  .horizontalMenucontainer,
  .page,
  .page-main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .page {
    .page-main {
      .page-body {
        flex-grow: 1;
      }
    }
  }
}

body.active {
  .header-brand,
  .header-fullscreen,
  .header-user {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .horizontal-click > i {
    display: block;
    height: 23px;
    width: 25px;
    margin-top: 14px;
    margin-right: 10px;
    background-size: 25px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.2);
    float: right;
  }
}

@media (max-width: 992px) {
  .page-body {
    margin-top: 25px;
  }
}

.tooltip-color-block {
  display: inline-block;
  border: 1px solid #fff;
  height: 12px;
  width: 12px;
  margin-right: 5px;
}

#chartjs-tooltip {
  opacity: 1;
  color: white;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.was-validated input:invalid ~ div > div {
  border-color: #dc0441;
}

@media (max-width: 767px) {
  .mobile-margin {
    margin-bottom: 79px !important;
  }
  .mobile-margin-top {
    margin-top: 0.5rem !important;
  }
  .hor-skin1 .horizontalMenu > .horizontalMenu-list > li:hover {
    background: white;
    border-top: 3px solid #182852;
  }
  .m2-no-md {
    margin-bottom: 0.5rem !important;
  }
  .fc-toolbar.fc-header-toolbar {
    flex-direction: column !important;

    .fc-left {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .fc-center {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .fc-right {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
  }
  .btn-list {
    .btn {
      margin-left: 0.5rem;
      margin-right: 0;
    }
  }
}

@media (min-width: 768px) {
  .desktop-margin {
    margin-bottom: 79px !important;
  }
}

.foo-overlay .DayPicker-Day,
.DayPickerInput .DayPicker-Day {
  padding: 0.35em 0.5em;
}

.hide {
  height: 0;
}

.square-box {
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.square-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font: 0/0 a;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    text-align: center;
    font: 0/0 a;
    vertical-align: middle;

    .old-ie & {
      display: inline-block;
    }
  }
  img {
    flex: 1;
    margin-right: 1rem;
    overflow: hidden;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.notification-message {
  white-space: pre-wrap;
}

.row-spacer {
  margin-bottom: 79px !important;
}

.modal-open {
  display: flex !important;
}
