.horizontalMenu > .horizontalMenu-list>li>ul.sub-menu {
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.horizontalMenu > .horizontalMenu-list>li:hover>ul.sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.horizontalMenu > .horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.horizontalMenu > .horizontalMenu-list>li>ul.sub-menu>li:hover>ul.sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.horizontalMenu > .horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.horizontalMenu > .horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li:hover>ul.sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.horizontalMenu > .horizontalMenu-list>li>.horizontal-megamenu {
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.horizontalMenu > .horizontalMenu-list>li:hover>.horizontal-megamenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}